
// import { mapState, mapGetters } from "vuex"
import mixins from "vue-typed-mixins"
import apiErrMixin from "@/mixins/api-err"
import statusQueryMixin from "@/mixins/status-query"
import { scrollToFirstError } from "@/utils/error"
import type { ConditionsContext } from "@/types/api/fields"

export default mixins(apiErrMixin, statusQueryMixin()).extend({
  props: {
    // isEdit: { type: Boolean, default: false },
    // isColleague: { type: Boolean, default: false },
  },

  // async created () {
  //   if (this.registrationModel) {
  //     this.model = JSON.parse(JSON.stringify(this.registrationModel))
  //     this.$store.commit("user/SET_REGISTRATION_MODEL", null)
  //   } else if (this.isEdit) {
  //     await this.preFill()
  //   } else if (!this.isColleague) {
  //     this.$set(this.model, "status", await this.getStatusFromQuery())
  //   }
  // },

  data: () => ({
    model: {} as Record<string, any>,
    isLoading: false,
    done: false,
  }),

  computed: {
    // ...mapState("user", ["registrationModel"]),
    // ...mapGetters("user", ["user"]),

    btnText (): string {
      const token = "button.submit"
      // let token = "pages.auth.registration.title"
      // if (this.isEdit) token = "button.save"
      // else if (this.isColleague) token = "button.add"
      return this.$t(token) as string
    },

    formContext (): ConditionsContext {
      return {
        isEdit: false, // this.isEdit,
      }
    },
  },

  methods: {
    async onSubmit (): Promise<void> {
      this.resetApiErr()
      this.isLoading = true

      // const { isEdit } = this
      // let action
      // if (this.isColleague)
      //   if (isEdit) action = this.updateColleague
      //   else action = this.createColleague
      // else if (isEdit) action = this.updateUser
      // else action = this.createUser

      const [, err] = await this.createUser()
      if (err) {
        this.apiErr = err.response.data
        await this.$nextTick()
        scrollToFirstError()
      }
      this.isLoading = false
    },

    async createUser (): Promise<[any, any]> {
      this.$set(this.model, "password", "1".repeat(6))
      const [, err] = await this.$api.user.createUser(this.model)
      if (err) return [null, err]
      // try {
      //   await this.$auth.setUserToken(res.token)
      // } catch {
      //   return [null, { message: this.$t("error.error"), context: {} }]
      // }
      // this.$router.push(this.localePath("/user"))
      this.done = true
      return [null, null]
    },

    // async updateUser (): Promise<[any, any]> {
    //   const [, err] = await this.$api.user.updateUser(this.model)
    //   if (err) return [null, err]
    //   this.$router.push(this.localePath("/user/profile"))
    //   this.$auth.fetchUser()
    //   return [null, null]
    // },

    // async createColleague (): Promise<[any, any]> {
    //   const [, err] = await this.$api.user.createColleague(this.model)
    //   if (err) return [null, err]
    //   this.$router.push(this.localePath("/user/payment"))
    //   return [null, null]
    // },

    // async updateColleague (): Promise<[any, any]> {
    //   const [, err] =
    //     await this.$api.user.updateColleague(this.$route.query.id as string, this.model)
    //   if (err) return [null, err]
    //   this.$router.push(this.localePath("/user/payment"))
    //   return [null, null]
    // },

    // async preFill (): Promise<void> {
    //   if (this.isColleague) {
    //     const [res, err] =
    //       await this.$api.user.getColleague(this.$route.query.id as string)
    //     if (err) return console.error(err.response.data)
    //     this.model = JSON.parse(JSON.stringify(res))
    //   } else {
    //     this.model = JSON.parse(JSON.stringify(this.user))
    //   }
    // },

    reset (): void {
      this.model = {}
      this.done = false
    },
  },
})
