import Vue from "vue"

const emptyApiErr = { message: "", context: {} as Record<string, string> }
type ApiErr = typeof emptyApiErr

export default Vue.extend({
  data: () => ({
    apiErr_: emptyApiErr,
  }),

  computed: {
    apiErr: {
      get (): ApiErr { return this.apiErr_ },
      set (apiErr: any) {
        if (apiErr) {
          const res = apiErr
          if (!("message" in res)) res.message = ""
          res.context = res.context || res.extensions?.context || {}
          // if (!res.context) res.context = {}
          this.apiErr_ = res
        } else { this.resetApiErr() }
      },
    },
  },

  methods: {
    resetApiErr () { this.apiErr_ = emptyApiErr },
  },
})
